import HModal from "@/components/ant-ext/HModal";
import {useEffect, useState} from "react";
import CommonLogin, {UserInfo} from "@/api/common-login";
import {Descriptions} from "antd";

type HLayoutAccountProps = {
    visible: boolean
    onClose: () => void
}

export default function HLayoutAccount(props: HLayoutAccountProps) {
    const {visible, onClose} = props;
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>()
    const editClose = () => {

        onClose()
    }
    useEffect(() => {
        if (visible) {
            CommonLogin.DoGetUserInfo({
                success: setUserInfo
            })
        }
    }, [visible])
    return <>

        <HModal title={"用户信息"} visible={visible} onCancel={editClose} footer={<></>}>
            <Descriptions bordered column={1}>
                <Descriptions.Item label={"编号"}>{userInfo?.adminCode} </Descriptions.Item>
                <Descriptions.Item label={"姓名"}>{userInfo?.adminName} </Descriptions.Item>
                <Descriptions.Item label={"登录名"}>{userInfo?.loginName} </Descriptions.Item>
                <Descriptions.Item label={"身份"}>{userInfo?.roleName} </Descriptions.Item>
                <Descriptions.Item label={"隶属"}>{userInfo?.orgNamePath} </Descriptions.Item>
                <Descriptions.Item label={"数据授权"}>
                    <div
                        dangerouslySetInnerHTML={{__html: userInfo === undefined ? "" : userInfo!.authOrgNamePath.join("<br/>")}}/>
                </Descriptions.Item>

            </Descriptions>

        </HModal>
    </>
}