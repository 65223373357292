import {RouteData, RouteElement, RouterLoader, RouterMetadata} from "@/config/route/route-types";
import {AnyParams} from "@/common/httptypes";
import {util} from "@/common/util";
import {ApplicationDataSource} from "@/config/route/loader-route-common";
export const dataSource: RouteData = {
    link: [],
    layout: [
        {name: "首页", path: "/home", element: "pages/home/index"},
        {
            name: "系统管理", icon: "SettingOutlined", path: "/admin", children: [
                {name: "管理员列表", path: "/admin/list", element: "pages/sys/admin/index"},
                {name: "角色管理", path: "/role/list", element: "pages/sys/role/index"},
                {name: "菜单管理", path: "/menu/list", element: "pages/sys/menu/index"},
                {name: "组织管理", path: "/org/list", element: "pages/sys/org/index"},

            ]
        },
    ]
}

export class LoaderLocalRouter implements RouterLoader {
    loaderMetadata(): Promise<RouterMetadata> {
        const list: RouteElement[] = []
        const mapping: AnyParams<RouteElement> = {}
        const layout: RouteElement[] = []

        util.Each([...ApplicationDataSource.link, ...dataSource.link], "children", 0, (p, level) => {
            let item = p as RouteElement
            item.layout = false
            list.push(item)
            mapping[item.path] = item
        })

        util.Each([...ApplicationDataSource.layout, ...dataSource.layout], "children", 0, (p, level) => {
            let item = p as RouteElement
            item.layout = true
            list.push(item)
            mapping[item.path] = item
            if (level === 0) {
                layout.push(item)
            }
        })
        return Promise.resolve({enable: true, buttons: [], list, mapping, layout});
    }

}