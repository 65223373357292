import {Form, Input, notification} from "antd";
import HModal from "@/components/ant-ext/HModal";
import HFormItem from "@/components/ant-ext/HFormItem";
import React from "react";
import CommonLogin from "@/api/common-login";
import LoginUser from "@/config/user";


type HLayoutPwdProps = {
    visible: boolean
    onClose: () => void
}

export default function HLayoutPwd(props: HLayoutPwdProps) {
    const {visible, onClose} = props;
    const [form] = Form.useForm();
    const close = () => {
        onClose()
        form.resetFields()
    }
    return <>
        <HModal type={"setUp"} width={"400px"} onCancel={close} visible={visible} title={"修改密码"}
                onOk={form.submit}
        >
            <Form form={form} labelCol={{span: 6}} onFinish={() => {
                const {loginPwd, checkPwd} = form.getFieldsValue()
                if (loginPwd !== checkPwd) {
                    notification.warning({message: "两次密码不匹配"})
                } else {
                    CommonLogin.DoUpdatePwd({
                        body: {loginPwd}, success: () => {
                            close()
                            LoginUser.logout()
                        }
                    })
                }
            }
            }>
                <HFormItem rules={[{required: true, message: '请输入新密码'}]} name={"loginPwd"}>
                    <Input.Password autoComplete="new-password" placeholder={"请输入新密码"}/>
                </HFormItem>
                <HFormItem rules={[{required: true, message: '请输入确认新密码'}]} name={"checkPwd"}>
                    <Input.Password autoComplete="new-password" placeholder={"请确认新密码"}/>
                </HFormItem>
            </Form>

        </HModal>
    </>
}