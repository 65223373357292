import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import {
    createHDataTableInitialState,
    HDataTableSlice,
    HDataTableState,
    TableAction
} from "@/components/ant-ext-table/redux/dataTableSlice";
import {useDispatch, useSelector} from "react-redux";


export const HAdminContext = configureStore({
    reducer: {
        dataTable: HDataTableSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        //关闭redux序列化检测
        serializableCheck: false
    })
});


export type HDataTableDispatch = typeof HAdminContext.dispatch;
export type HAdminState = ReturnType<typeof HAdminContext.getState>;
export const HDataTableSelector = (state: HAdminState) => state.dataTable;

const defaultAction = new TableAction("nil", "", undefined, [], [])

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function HDataTableActionSelector<RecordType>(state: HAdminState) {
    return state.dataTable.table.action || defaultAction
}

const initialState = createHDataTableInitialState()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function HDataTableDataSelector<RecordType>(state: HAdminState) {
    return state.dataTable.table.pathname === window.location.pathname ? state.dataTable.table : initialState
}

const {resetAction} = HDataTableSlice.actions
export type TableActionRedux<RecordType> = { action: (TableAction<RecordType>), reset: () => void, dispatch: HDataTableDispatch }

export function useTableActionRedux<RecordType>(): TableActionRedux<RecordType> {
    const dispatch = useDispatch<HDataTableDispatch>()
    return {
        action: useSelector<HAdminState, TableAction<RecordType>>(HDataTableActionSelector),
        reset: () => dispatch(resetAction()),
        dispatch
    }
}

export function useTableDataRedux<RecordType>(): [(HDataTableState<RecordType>["table"]), HDataTableDispatch] {
    return [useSelector<HAdminState, HDataTableState<RecordType>["table"]>(HDataTableDataSelector), useDispatch<HDataTableDispatch>()]
}


export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    HAdminState,
    unknown,
    Action<string>
>;