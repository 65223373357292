import React, {Key, lazy} from "react";
import {Spin} from "antd";
import {AnyParams} from "@/common/httptypes";
import {HSearchTreePops} from "@/components/ant-ext/HSearchTree";
import Constant from "@/config/constant";
import cookie from "@/common/cookie";

const colors = [
    '#2f54eb',
    'rgb(246,179,7)',
    'rgb(80, 193, 233)',
    'rgb(169, 109, 243)',
    'rgb(245,97,58)',
    'rgb(103, 197, 12)',
    'rgb(80, 193, 233)',
    'rgb(110,58,120)',
];

export class Util {

    NotNull(data: any): boolean {
        return data !== null && data !== undefined && data !== '';
    }

    IsNull(data: any): boolean {
        return data === null || data === undefined || data === '';
    }

    Lazy(path?: string) {
        if (path) {
            const Comp = React.lazy(() => import(`@src/${path}`));
            return (
                <React.Suspense fallback={<>   <Spin/></>}>
                    <Comp/>
                </React.Suspense>
            );
        } else {
            return <></>
        }

    };

    LazyIcon(name?: string) {
        if (name) {
            return <React.Suspense fallback={<><Spin/></>}>
                {React.createElement(lazy(() => import('@ant-design/icons').then(module => {
                    // @ts-ignore
                    return {default: module[name]};
                })), {})}
            </React.Suspense>
        } else {
            return <></>
        }

    };

    Each(dataSource: AnyParams<any>[], childrenName: string, level: number, func: (element: AnyParams<any>, level: number) => void) {
        dataSource.forEach(p => {
            func(p, level)
            if (Array.isArray(p[childrenName])) {
                util.Each(p[childrenName], childrenName, level + 1, func)
            }
        })
    }

    getColor(str: string) {
        if (!str) return colors[0];
        return colors[str.charCodeAt(0) % colors.length];
    }

    joinUrl(url: string, queryString?: string) {
        if (queryString) {
            if (url.indexOf("?") !== -1) {
                return url + "&" + queryString
            } else {
                return url + "?" + queryString
            }
        }
        return url;
    }

    deepClone<T>(input: T): T {
        if (input === undefined || input === null) {
            return input
        } else {
            return JSON.parse(JSON.stringify(input))
        }
    }

    getTreeAllKey(keys: Key[], treData: HSearchTreePops["treeData"]) {
        treData.forEach(p => {
            if (p.children && p.children.length > 0) {
                keys.push(p.key)
                this.getTreeAllKey(keys, p.children)
            }
        })
    }

    toLogin() {
        cookie.deleteCookie("token")
        window.location.href = Constant.LoginPage + "?to=" + window.location.pathname
    }
}


export const util = new Util();