import Constant from "@/config/constant";
import {LoaderLocalRouter} from "@/config/route/loader-route-local";
import {LoaderOnlineRouter} from "@/config/route/loader-route-online";


export function LoaderRoute() {
    if (Constant.Identity) {
        console.log("权限认证已开启，路由组件已受控")
        return new LoaderOnlineRouter().loaderMetadata()
    } else {
        console.log("权限认证已关闭,加载本地路由")
        return new LoaderLocalRouter().loaderMetadata()
    }
}