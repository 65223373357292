import {Button, Result} from "antd";
import Constant from "@/config/constant";
import {useMemo} from "react";
import cookie from "@/common/cookie";
import {util} from "@/common/util";

export default function ERR403() {

    const extra = useMemo(() => {
        const list = []
        if (cookie.getCookie("token")) {
            list.push(<Button type="primary" onClick={() => {
                window.location.href = Constant.LayoutHome
            }} key="home">
                前往首页
            </Button>)
        } else {
            list.push(
                <Button key="login" type="primary" onClick={() => {
                    util.toLogin()
                }}>前往登陆</Button>)
        }
        return list;
    }, [])

    return <Result
        status="403"
        title="403"
        subTitle="暂无访问权限 !"
        extra={extra}
    />
}