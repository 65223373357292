import dayjs from "dayjs";

export const DatePrickRanges = {
    '今天': [dayjs().startOf("day"), dayjs().endOf("day")],
    '最近七天': [dayjs().add(-6, "day").startOf("day"), dayjs().endOf("day")],
    '最近三十天': [dayjs().add(-30, "day").startOf("day"), dayjs().endOf("day")],
    '本周': [dayjs().startOf('week').startOf("day"), dayjs().endOf("day")],
    '本月': [dayjs().startOf('month').startOf("day"), dayjs().endOf("day")],
    '上周': [dayjs().add(-1, "week").startOf('week').startOf("day"), dayjs().add(-1, "week").endOf('week').endOf("day")],
    '上月': [dayjs().add(-1, "month").startOf('month').startOf("day"), dayjs().add(-1, "month").endOf('month').endOf("day")],
}