import React from 'react';
import {Select, SelectProps} from 'antd';
import {ReqProps} from "@/common/http";
import {AnyParams} from "@/common/httptypes";


export type SelectDataMethod = (param: ReqProps<SelectDataArray>) => void
export type SelectDataArray = { title: string | number | boolean | React.ReactNode, value: HStaticSelectProps["value"] }[]
export type HStaticSelectProps = SelectProps & {
    params?: AnyParams<any>
    dataSource: SelectDataMethod | SelectDataArray
    width?: string
}

export default class HStaticSelect extends React.Component<HStaticSelectProps, any> {

    state: { dataSource: SelectDataArray, width?: string } = {
        dataSource: [],
        width: "100%"
    }

    constructor(props: HStaticSelectProps | Readonly<HStaticSelectProps>) {
        super(props);

        if (props.width !== undefined) {
            this.state.width = props.width
        }
    }

    componentDidMount() {
        if (typeof this.props.dataSource === "function") {
            this.props.dataSource({
                params: this.props.params, success: (data) => {
                    this.setState({dataSource: data})
                }
            })
        } else {
            this.setState({dataSource: this.props.dataSource})
        }
    }

    componentDidUpdate(prevProps: Readonly<HStaticSelectProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.dataSource !== prevProps.dataSource || this.props.params !== prevProps.params) {
            if (typeof this.props.dataSource === "function") {
                this.props.dataSource({
                    params: this.props.params, success: (data) => {
                        this.setState({dataSource: data})
                    }
                })
            } else {
                this.setState({dataSource: this.props.dataSource})
            }

        }
        if (this.props.width !== prevProps.width) {
            this.setState({width: this.props.width})
        }
    }


    render() {
        const {dataSource, params, width, style = {}, allowClear = true, ...other} = this.props
        return (
            <Select {...other} allowClear={allowClear} style={{width: this.state.width, ...style}}>
                {this.state.dataSource?.map((item, i) => (
                    <Select.Option key={i}
                                   value={item.value}>
                        {item.title}
                    </Select.Option>
                ))}
            </Select>
        );
    }
}
