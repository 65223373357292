import {AnyParams} from "@/common/httptypes";
import CommonLogin from "@/api/common-login";
import {ParseResponse} from "@/common/http";
import {util} from "@/common/util";
import {RouteElement, RouterLoader, RouterMetadata} from "@/config/route/route-types";
import {ApplicationDataSource} from "@/config/route/loader-route-common";
import Cookie from "@/common/cookie";

const defaultNullRoute = {link: [], layout: [], buttons: []}

export class LoaderOnlineRouter implements RouterLoader {
    async loaderMetadata(): Promise<RouterMetadata> {
        const list: RouteElement[] = []
        const mapping: AnyParams<RouteElement> = {}
        const layout: RouteElement[] = []

        let data;
        if (Cookie.getCookie("token")) {
            const response = await CommonLogin.Router({})
            data = await ParseResponse(response) || defaultNullRoute
            console.log("当前已登陆，加载授权路由")
        } else {
            data = {link: [], layout: [], buttons: []}
            console.log("当前未登录，只加载公共路由数据")
        }


        util.Each([...ApplicationDataSource.link, ...data.link], "children", 0, (p, level) => {
            let item = p as RouteElement
            item.layout = false
            list.push(item)
            mapping[item.path] = item
        })


        util.Each([...ApplicationDataSource.layout, ...data.layout], "children", 0, (p, level) => {
            let item = p as RouteElement
            item.layout = true
            list.push(item)
            if (item.path) {
                mapping[item.path] = item
            }
            if (level === 0) {
                layout.push(item)
            }
        })
        return {enable: true, list, mapping, layout, buttons: data.buttons}
    }
}