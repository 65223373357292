import React from 'react';
import {TreeSelect, TreeSelectProps} from 'antd';
import {ReqProps} from "@/common/http";
import {AnyParams} from "@/common/httptypes";
import {SelectDataArray} from "@/components/ant-ext/HStaticSelect";


export type SelectTreeMethod = (param: ReqProps<SelectTreeData[]>) => void

export type SelectTreeData = { title: string, value: any, children: SelectTreeData[] }
export type HTreeSelectProps = {
    params?: AnyParams<any>
    dataSource: SelectTreeMethod | SelectTreeData[]
    width?: string

} & TreeSelectProps

export class HTreeSelect extends React.Component<HTreeSelectProps, any> {
    state: { dataSource: SelectDataArray, width?: string } = {
        dataSource: [],
        width: "100%"
    }

    constructor(props: HTreeSelectProps | Readonly<HTreeSelectProps>) {
        super(props);
        if (props.width !== undefined) {
            this.state.width = props.width
        }
    }

    componentDidMount() {
        if (typeof this.props.dataSource === "function") {
            this.props.dataSource({
                params: this.props.params, success: (data) => {
                    this.setState({dataSource: data})
                }
            })
        } else {
            this.setState({dataSource: this.props.dataSource})
        }
    }

    componentDidUpdate(prevProps: Readonly<HTreeSelectProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.dataSource !== prevProps.dataSource || this.props.params !== prevProps.params) {
            if (typeof this.props.dataSource === "function") {
                this.props.dataSource({
                    params: this.props.params, success: (data) => {
                        this.setState({dataSource: data})
                    }
                })
            } else {
                this.setState({dataSource: this.props.dataSource})
            }

        }
        if (this.props.width !== prevProps.width) {
            this.setState({width: this.props.width})
        }
    }

    render() {
        const {dataSource, params, width, style = {}, treeLine = true, allowClear = true, ...other} = this.props
        return (
            <TreeSelect{...other} treeLine={treeLine} allowClear={allowClear} treeData={this.state.dataSource}
                       style={{width: this.state.width, ...style}}/>
        );
    }
}
