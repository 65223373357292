import {NavigateFunction} from "react-router/dist/lib/hooks";
import {MenuProps} from "antd";
import {useNavigate} from "react-router";
import React, {useEffect} from "react";
import Constant from "@/config/constant";
import {To} from "react-router-dom";
import {RouteElement} from "@/config/route/route-types";
import {util} from "@/common/util";
import {LoaderRoute} from "@/config/route/loader-route";


let HNavigate: NavigateFunction;
let HRouteMedata = await LoaderRoute()
console.log("路由数据加载完毕", HRouteMedata)


export function InitializeNavigate() {
    let navigate = useNavigate()
    useEffect(() => {
        HNavigate = navigate
    }, [navigate])
    useEffect(() => {
        if (window.location.pathname !== "/login" && !hasIdentityUrl(window.location.pathname)) {
            util.toLogin()
        }
    }, [navigate])

    return <></>
}

export function hasEmpower(powerCode ?: string): boolean {
    if (Constant.Identity) {
        if (powerCode !== undefined && powerCode !== "") {
            return HRouteMedata.buttons.indexOf(powerCode) !== -1
        }
    }

    return true;
}

export function hasIdentityUrl(toUrl?: To): boolean {
    if (Constant.Identity && toUrl !== undefined) {
        let url: string;
        if (typeof toUrl === "string") {
            url = toUrl
        } else {
            url = toUrl.pathname!;
        }
        return HRouteMedata.mapping[url] !== undefined
    }
    return true;
}

function getLayoutItem(element: RouteElement, setSelectKey: (key: string) => void): Required<MenuProps>['items'][number] {
    return {
        key: element.path,
        icon: util.LazyIcon(element.icon),
        children: element.children && element.children.length > 0 ? element.children.map(p => getLayoutItem(p, setSelectKey)) : undefined,
        label: <span>{element.name}</span>,
    };
}


export function getLayout(setSelectKey: (key: string) => void): Required<MenuProps>['items'][number][] {
    let items: Required<MenuProps>['items'][number][] = []
    HRouteMedata.layout.forEach(p => {
        if (p.path !== Constant.LayoutHome) {
            items.push(getLayoutItem(p, setSelectKey))
        }
    })
    return items
}

export {HRouteMedata, HNavigate}