import {Navigate, Route, Routes} from 'react-router-dom';
import React, {useMemo} from 'react';
import Constant from '@/config/constant';
import Progress from "@/components/nprogress";
import HLayout from "@/components/ant-ext-layout/HLayout";
import {Animation} from '@/common/animation';
import {RouteElement} from "@/config/route/route-types";
import {HRouteMedata, InitializeNavigate} from "@/config/route/route-func";
import ERR403 from "@/config/403";

export default function RouteApp() {
    const routes = useMemo(() => {
        const router = [];
        HRouteMedata.list.forEach((p, i) => {
            if (p.element) {
                router.push(<Route key={i} path={p.path}
                                   element={lazy(p)}/>);
            }
        })
        router.push(<Route key={"any"} path={'*'} element={<ERR403/>}/>);
        router.push(<Route key={"403"} path={'/403'} element={<ERR403/>}/>);
        router.push(<Route key={"/"} path={'/'} element={<Navigate to={Constant.LayoutHome}/>}/>);
        return router;
    }, []);
    return <>
        <Constant.RouterType>

            <Routes>
                {routes}
            </Routes>
        </Constant.RouterType>

    </>
}


function lazy(element: RouteElement) {
    let Comp = React.lazy(() => import(`@/${element.element}`));
    if (element.layout) {
        return (

            <HLayout>
                <InitializeNavigate/>
                <Animation>
                    <React.Suspense fallback={<>   <Progress/></>}>
                        <Comp/>
                    </React.Suspense>
                </Animation>
            </HLayout>


        )
    } else {
        return (
            <>
                {/*<Animation>*/}
                <React.Suspense fallback={<>   <Progress/></>}>
                    <InitializeNavigate/>
                    <Comp/>
                </React.Suspense>
                {/*</Animation>*/}
            </>
        );

    }


}
