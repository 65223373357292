import {Layout, Menu} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import React, {useEffect, useMemo, useState} from 'react';
import '@/components/ant-ext-layout/style.less';
import {getLayout, HNavigate, HRouteMedata} from "@/config/route/route-func";
import Constant from "@/config/constant";
import HLayoutLogo from "@/components/ant-ext-layout/HLayoutLogo";
import AccountOpt from "@/components/ant-ext-layout/HLayoutAccountOpt";
import HLayoutTabs from "@/components/ant-ext-layout/HLayoutTabs";
import {HContent} from "@/components/common/HContent";
import {Provider} from "react-redux";
import {HAdminContext} from "@/components/ant-ext-table/redux/HAdminContext";

const {Header, Content, Sider} = Layout;

export default function HLayout(props: { children: React.ReactNode }) {
    const [collapsed, setCollapsed] = useState(false);
    const [selectKey, setSelectKey] = useState<string>("")
    const [openKeys, setOpenKeys] = useState<string[]>([])
    const items = useMemo(() => {
        return getLayout(setSelectKey)
    }, [])


    useEffect(() => {
        let key = window.location.pathname
        let current = HRouteMedata.mapping[key];
        setSelectKey(current.path)
        setOpenKeys([current.path])
    }, []);

    const trigger = useMemo(() => {
        if (collapsed) {
            return <MenuUnfoldOutlined className="trigger" style={{padding: "12px"}}
                                       onClick={() => setCollapsed(!collapsed)}/>
        } else {
            return <MenuFoldOutlined className="trigger" style={{padding: "12px"}}
                                     onClick={() => setCollapsed(!collapsed)}/>
        }
    }, [collapsed]);


    return <>
        <Layout hasSider>
            <Sider className={'fixedSider'}
                   collapsed={collapsed}
                   onCollapse={setCollapsed}>
                <HLayoutLogo collapsed={collapsed} onClick={() => {
                    setSelectKey(Constant.LayoutHome)
                }}/>
                <Menu className={'adminLayout'} theme='dark' mode='inline'
                      items={items}
                      onSelect={(selectInfo) => {
                          setSelectKey(selectInfo.key)
                          HNavigate(selectInfo.key)
                      }}
                      onOpenChange={(item) => {
                          let keys = [...openKeys]
                          keys[0] = item.length > 0 ? item[item.length - 1] : "";
                          setOpenKeys(keys)
                      }}
                      selectedKeys={[selectKey]}
                      openKeys={openKeys}
                />
            </Sider>
            <Layout className='site-layout'>
                <Header className='site-layout-background' >
                    {trigger}
                    <AccountOpt/>
                </Header>
                <HLayoutTabs selectKey={selectKey} setSelectKey={setSelectKey}/>
                <Content color={'white'}
                         style={{padding: '0 16px', height: '80vh', overflow: 'initial'}}>
                    <HContent>
                        <Provider store={HAdminContext}>
                            {
                                props.children
                            }
                        </Provider>
                    </HContent>
                </Content>
            </Layout>
        </Layout>
    </>;
}