import Constant from "@/config/constant";

// @ts-ignore
import md5 from "md5";
import {HNavigate} from "@/config/route/route-func";
import Cookie from "@/common/cookie";
import {util} from "@/common/util";

const loginUserKey = Constant.Prefix + "_token"
const loginUserSign = Constant.Prefix + "_sign"


export class User {
    token: string | undefined;
    username: string | undefined;
    exists: boolean | undefined;

    constructor() {
        this.exists = false;
    }

    /**
     * 清除缓存
     */
    clear() {
        // @ts-ignore
        Object.keys(this).forEach(p => this[p] = undefined)
        this.exists = false;
        localStorage.removeItem(loginUserKey);
        localStorage.removeItem(loginUserSign);

    }

    /**
     * 同步缓存
     */
    storage() {
        const str = JSON.stringify(this)
        const sign = md5(str + Constant.Secret)
        localStorage.setItem(loginUserKey, str);
        localStorage.setItem(loginUserSign, sign);
    }

    /**
     * 修改登录信息
     * @param data
     */
    save(data: User) {
        // @ts-ignore
        Object.keys(data).forEach(p => this[p] = data[p])
        this.storage()
    }

    /**
     * 懒加载获取User
     */
    sync(): User {
        if (this.exists) {
            return this;
        } else {
            this.reload()
            return this;
        }
    }

    /**
     * 重载User
     */
    reload() {
        const str = localStorage.getItem(loginUserKey);
        if (str && str !== "") {
            const sign = md5(str + Constant.Secret)
            if (sign === localStorage.getItem(loginUserSign)) {
                const u: User = JSON.parse(str)
                // @ts-ignore
                Object.keys(this).forEach(p => this[p] = u[p])
            } else {
                this.clear()
                HNavigate("/503")
            }

        }
    }

    logout() {
        Cookie.deleteCookie("token")
        util.toLogin()
    }

}

const LoginUser: User = new User().sync();
export default LoginUser;