import miniLogo from './mini-logo.png';
import './style.less';
import React, {useMemo} from 'react';
import {Divider} from "antd";
import Constant from "@/config/constant";

type HLayoutLogoProps = {
    collapsed: boolean
    onClick: () => void
}
export default function HLayoutLogo(props: HLayoutLogoProps) {
    const {collapsed, onClick} = props;
    const logo = useMemo(() => {
        return collapsed ? (
                <>

                    <div className={'mini-logo'} onClick={onClick}>
                        <img className='mini-logoImg' src={miniLogo} alt='mini-logoImg'/>
                    </div>

                </>
            ) :
            (
                <>
                    <div className={'logo'} onClick={onClick}>
                        <img className='logoImg' src={miniLogo} alt='logo'/>
                    </div>
                    <Divider style={{color: "white"}} plain orientation="center">{Constant.WebsiteName}</Divider>
                </>
            )
    }, [collapsed, onClick])

    return (
        <>
            {logo}
        </>
    );
}
