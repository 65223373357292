import React from "react";
import {Dropdown, Tabs, TabsProps} from "antd";
import {HNavigate, HRouteMedata} from "@/config/route/route-func";
import Constant from "@/config/constant";

type HLayoutTabsProps = {
    selectKey: string;
    setSelectKey: (key: string) => void;
}

export default class HLayoutTabs extends React.Component<HLayoutTabsProps, any> {

    state: {
        tabs: TabsProps["items"]

    } = {tabs: []}

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: HLayoutTabsProps) {
        super(props);
        if (this.state.tabs!.length === 0){
            this.state.tabs!.push(this.createTags(Constant.LayoutHome))
        }

    }
    componentDidUpdate(prevProps: Readonly<HLayoutTabsProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.selectKey !== prevProps.selectKey ){
            let tabs = this.state.tabs!
            if (!tabs.find(p => p.key === this.props.selectKey)) {
                this.setState({tabs:[...tabs, this.createTags(this.props.selectKey)]})
                HNavigate(this.props.selectKey)
            }

        }
    }

    createTags(key: string) {
        return {
            key: key,
            label: <Dropdown menu={{
                items: [
                    {
                        label: "关闭左侧", key: "1", onClick: () => {
                            let {tabs} = this.state;
                            let newTabs = []
                            let start = false;

                            for (let i in tabs!) {
                                let tab = tabs[i]
                                if (start) {
                                    newTabs.push(tab)
                                } else if (tab.key === key) {
                                    newTabs.push(tab)
                                    start = true;
                                }
                            }
                            this.setState({tabs: newTabs})
                        }
                    },
                    {
                        label: "关闭右侧", key: "2", onClick: () => {
                            let {tabs} = this.state;
                            let newTabs = []
                            for (let i in tabs!) {
                                if (tabs[i].key === key) {
                                    newTabs.push(tabs[i])
                                    break
                                } else {
                                    newTabs.push(tabs[i])
                                }
                            }
                            this.setState({tabs: newTabs})
                        }
                    },
                    {
                        label: "关闭其他", key: "3", onClick: () => {
                            let {tabs} = this.state;
                            let tab = tabs!.find(p => p.key === key)
                            this.setState({tabs: [tab!]})
                        }
                    },
                    {
                        label: "关闭全部", key: "4", onClick: () => {
                            this.setState({tabs: [this.createTags(Constant.LayoutHome)]})
                        }
                    }
                ]
            }} trigger={['contextMenu']}>
                <div>{HRouteMedata.mapping[key]?.name}</div>

            </Dropdown>
        }
    }


    render() {
        const {selectKey, setSelectKey} = this.props
        return <Tabs
            hideAdd
            tabBarStyle={{margin: "0px",backgroundColor:"white",padding:"0 16px"}}
            animated={{inkBar: true, tabPane: true}}
            activeKey={selectKey}
            type="editable-card"
            onChange={(key) => {
                setSelectKey(key)
                HNavigate(key)
            }}
            onEdit={(key, action) => {
                const {tabs} = this.state
                let newTabs = tabs!.filter(p => p.key !== key)
                if (selectKey === key) {
                    if (newTabs.length === 0) {
                        newTabs.push(this.createTags(Constant.LayoutHome))
                        setSelectKey(Constant.LayoutHome)
                        HNavigate(Constant.LayoutHome)
                    } else {
                        setSelectKey(newTabs[newTabs.length - 1].key)
                        HNavigate(newTabs[newTabs.length - 1].key)
                    }
                }
                this.setState({tabs: newTabs})
            }}
            items={this.state.tabs}
        >
        </Tabs>
    }
}