import React, {useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";
import "./animation.less"

type AnimationProps = {
    children: React.ReactNode;
    type?: "inout" | "fade";
}

export function Animation(props: AnimationProps) {
    const {children, type = "inout"} = props;
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        setVisible(true)
    }, [])

    return <CSSTransition in={visible}
                          classNames={type}
                          timeout={1500}>
        <div>
            {children}
        </div>
    </CSSTransition>
}