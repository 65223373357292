import React from 'react';
import 'antd/dist/reset.css';
import zhCN from 'antd/es/locale/zh_CN'
import {ConfigProvider} from "antd";
import 'dayjs/locale/zh-cn';
import RouteApp from "@/config/route/route-app";

function App() {


    return (
        <ConfigProvider locale={zhCN}>
            <RouteApp/>
        </ConfigProvider>
    );
}

export default App;
