import {Modal} from "antd";
import * as React from "react";
import {CSSProperties, ReactNode} from "react";

export type HModalProps = {
    onCancel: () => void;
    onOk?: (e: React.MouseEvent<HTMLElement>) => void;
    visible: boolean;
    type?: 'setUp' | 'full' | 'small' | "large" | "default" | 'mini';
    bodyStyle?: CSSProperties;
    afterClose?: () => void;
    title: ReactNode;
    okText?: string;
    cancelText?: string;
    footer?: ReactNode;
    centered?: boolean;
    style?: CSSProperties;
    maskClosable?: boolean;
    children: ReactNode;
    width?: string | number;
    confirmLoading?: boolean;
}


export default class HModal extends React.Component<HModalProps, any> {
    state: { style?: CSSProperties, bodyStyle?: CSSProperties } = {}

    constructor(props: HModalProps) {
        super(props);
        this.state = this.readStyle()
    }

    componentDidUpdate(prevProps: Readonly<HModalProps>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.style !== prevProps.style || this.props.bodyStyle !== prevProps.bodyStyle) {
            this.setState(this.readStyle())
        }
    }

    readStyle() {
        const {type = "default", style = {}, bodyStyle = {}} = this.props
        switch (type) {
            case "full":
                return {
                    style: {...style, width: '100vw', maxWidth: '100vw', top: 0, paddingBottom: 0},
                    bodyStyle: {...bodyStyle, height: "calc(100vh - 55px - 53px)", overflow: "auto"}
                };
            case "setUp":
                return {
                    style: {...style},
                    bodyStyle: {...bodyStyle},
                };
            case 'small':
                return {
                    style: {...style, width: '35vw'}
                    , bodyStyle: {...bodyStyle, height: "calc(50vh - 55px - 53px)", overflow: "auto"}
                };
            case 'mini':
                return {
                    style: {...style, width: '20vw'}
                    , bodyStyle: {...bodyStyle}
                };
            case 'large':
                return {
                    style: {...style, width: '70vw'}
                    , bodyStyle: {...bodyStyle, height: "calc(70vh - 55px - 53px)", overflow: "auto"}
                };
            case 'default':
            default:
                return {
                    style: {...style, width: '50vw'}
                    , bodyStyle: {...bodyStyle, height: "calc(60vh - 55px - 53px)", overflow: "auto"}
                };
        }

    }


    render() {
        const {
            okText = "确定",
            cancelText = "取消",
            maskClosable = true,
            visible,
            onOk,
            afterClose,
            title,
            footer,
            onCancel,
            children,
            centered,
            confirmLoading
        } = this.props;
        return <>
            <Modal className={"adminModal"} maskClosable={maskClosable}
                   confirmLoading={confirmLoading}
                   open={visible}
                   centered={centered}
                   afterClose={afterClose}
                   width={this.props.width}
                   bodyStyle={this.state.bodyStyle}
                   cancelText={cancelText}
                   okText={okText}
                   title={title}
                   style={this.state.style}
                   footer={footer}
                   onOk={onOk}
                   onCancel={onCancel}>
                {children}
            </Modal>
        </>
    }
}
