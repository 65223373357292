import Http, {HttpParamsProps, ReqProps} from "@/common/http";

export type UserInfo = {
    adminCode: string;
    adminName: string;
    roleName: string;
    loginName: string;
    orgNamePath: string;
    loginPwd: string;
    authOrgNamePath: string[]
}

class CommonLogin {
    DoGetCaptcha(props: ReqProps<{ base64: string }>) {
        Http.doGet("/api/captcha", props)
    }

    DoLogin(props: ReqProps<any>) {
        Http.doPost("/api/login", props)
    }

    Router(props: HttpParamsProps): Promise<Response> {
        return Http.get("/api/router", props)
    }

    DoGetUserInfo(props: ReqProps<UserInfo>) {
        Http.doGet("/api/userinfo", props)
    }

    DoGetUsername(props: ReqProps<{ adminName: string }>) {
        Http.doGet("/api/username", props)
    }

    DoUpdatePwd(props: ReqProps<any>) {
        Http.doPost("/api/updatePwd", props)
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CommonLogin()