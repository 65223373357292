import "./HContent.less"
import React, {CSSProperties} from "react";

export type HContentProps ={
    style?: CSSProperties;
    children?: React.ReactNode;
}


export function HContent(props: HContentProps) {
    const {children} = props;
    return <div style={props.style} className={"content"}>
        {children}
    </div>;
}
