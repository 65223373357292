import {Avatar, Dropdown} from "antd";
import {PoweroffOutlined, ToolOutlined, UserOutlined, UserSwitchOutlined} from "@ant-design/icons";
import React, {useEffect, useMemo, useState} from "react";
import LoginUser from "@/config/user";
import {util} from "@/common/util";
import HLayoutAccount from "@/components/ant-ext-layout/HLayoutAccount";
import CommonLogin from "@/api/common-login";
import HLayoutPwd from "@/components/ant-ext-layout/HLayoutPwd";


export default function AccountOpt() {

    const [accountVisible, setAccountVisible] = useState<boolean>(false)
    const [pwdVisible, setPwdVisible] = useState<boolean>(false)
    const [username, setUsername] = useState("未登录")

    useEffect(() => {
        CommonLogin.DoGetUsername({success: (data) => setUsername(data.adminName)})
    }, [])

    const item = useMemo(() => {
        return {
            option: [
                {
                    key: '1',
                    label: '个人信息',
                    icon: <UserSwitchOutlined/>,
                    onClick: () => {
                        setAccountVisible(true)
                    }
                }, {
                    key: '2',
                    label: '修改密码',
                    icon: <ToolOutlined/>,
                    onClick: () => {
                        setPwdVisible(true)
                    }
                }, {
                    key: '3',
                    label: '退出登陆',
                    icon: <PoweroffOutlined/>,
                    onClick: () => {
                        LoginUser.logout()
                    }
                }
            ]
        };
    }, [])

    return <>
        <HLayoutAccount visible={accountVisible} onClose={() => setAccountVisible(false)}/>
        <HLayoutPwd visible={pwdVisible} onClose={() => setPwdVisible(false)}/>
        <div style={{paddingRight: 12, float: 'right'}}>
            <div>
                <Dropdown menu={{items: item.option}}>
                    <div className={'action accountOpt'}>
                        <Avatar size='small' style={{backgroundColor: util.getColor(username)}}>
                            {(username[0]).toUpperCase()}
                        </Avatar>
                        <span className={'userName'}>{username}</span>
                        <UserOutlined/>
                    </div>
                </Dropdown>
            </div>
        </div>
    </>
}
