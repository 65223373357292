class Cookie {


    setCookie(name: string, value: string, days: number) {
        const expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    }

    getCookie(name: string) {
        const matches = document.cookie.match(
            new RegExp(
                `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`
            )
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    deleteCookie(name: string) {
        this.setCookie(name, "", -1);
    }
}

export default new Cookie()